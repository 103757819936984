@function selfAdapt($px) {
    @return 100vw * ($px / 375);
}

.user {
    background: #ffffff;

    .container {
        padding-bottom: 30px;

        .tap {
            display: flex;
            justify-content: space-between;
            align-items: stretch;

            @apply px-[20px];

            ul {
                display: flex;

                li {
                    font-size: selfAdapt(16);
                    font-family: PopinsSemi, PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;

                    a {
                        padding-bottom: selfAdapt(8);
                        font-size: selfAdapt(14);
                        font-family: PopinsSemi, Poppins;
                        font-weight: 600;
                        color: #2e384e;
                        line-height: selfAdapt(25);
                        position: relative;

                        .bar {
                            display: block;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            margin: auto;
                            width: 12px;
                            height: 3px;
                            background: #ff3e5f;
                            border-radius: 10px;
                        }
                    }
                }
            }

            .more {
                width: selfAdapt(52);
                height: selfAdapt(24);
                border-radius: selfAdapt(12);
                text-align: center;
                line-height: selfAdapt(24);
                border: 1px solid #e8e8e8;

                font-size: selfAdapt(10);
                font-family: Popins, Poppins-Regular, Poppins;
                font-weight: 400;
                color: #708096;
            }
        }

        .itemTitle2 {
            padding: 0 0 16px;
            font-size: selfAdapt(10);
            font-family: Popins, Poppins-Regular, Poppins;
            font-weight: 400;
            color: #b8c0cb;
            line-height: selfAdapt(16);
        }

        .item {
            border-bottom: 1px solid #f0f0f0;
            // background: #e6e6e6;
            display: flex;
            align-items: center;
            padding: selfAdapt(11) selfAdapt(7) selfAdapt(9) 0;
            justify-content: space-between;

            .sort {
                font-size: selfAdapt(16);
                font-family: PingFangSC-Semibold, PingFang SC, popinsSemi;
                font-weight: 600;
                color: #191919;
                line-height: selfAdapt(22);
                margin-right: selfAdapt(11);
            }

            .atr {
                @apply flex items-center;
            }

            .info {
                // display: flex;
                margin-left: selfAdapt(12);

                .name {
                    font-size: selfAdapt(14);
                    font-family: PingFangSC-Semibold, PingFang SC, popinsSemi;
                    font-weight: 600;
                    color: #191919;
                    //   line-height: 20px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    max-width: selfAdapt(103);
                }

                .count {
                    display: flex;
                    font-size: selfAdapt(10);
                    font-family: PingFangSC-Regular, PingFang SC, PingRC;
                    font-weight: 400;
                    color: #708096;
                    //   line-height: 14px;
                }
            }

            .add {
                font-size: selfAdapt(16);
                font-family: Manrope-Bold, Manrope;
                font-weight: bold;
                color: #191919;
                text-align: right;
                // line-height: 21px;
            }

            .grow {
                text-align: right;
                font-size: selfAdapt(10);
                font-family: PingFangSC-Regular, PingFang SC, PingRC;
                font-weight: 400;
                color: #708096;
                // line-height: 14px;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .user {
        display: none;
    }
}
